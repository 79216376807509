import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';

export interface RenderModalEventListenerProps {
  toggleModal: (showModal: boolean) => void;
}

export const RenderModalEventListener: FunctionComponent<
  RenderModalEventListenerProps
> = (props: RenderModalEventListenerProps): JSX.Element => {
  const { toggleModal } = props;
  useEffect(() => {
    const eventListener = () => {
      toggleModal(true);
    };
    window.addEventListener('renderBeratungsweiche', eventListener, false);

    return () => {
      window.removeEventListener('renderBeratungsweiche', eventListener);
    };
  }, [toggleModal]);
  return <></>;
};
