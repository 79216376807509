import * as React from 'react';

interface StepContainerProps {
  isActiveStep: boolean;
}

export class StepContainer extends React.Component<StepContainerProps> {
  public render() {
    if (!this.props.isActiveStep) {
      return null;
    }

    return <>{this.props.children}</>;
  }
}
