import { DateInputValue } from '@eg/elements/DateInput';
import { DateInputRow } from '@eg/elements/DateInputRow';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { getErrorMessage } from '../../common/errorHelper';
import { validateDatum } from '../../common/validation/validation';

export interface DateInputRowWrapperProps {
  form: FormikProps<any>;
  name: string;
  label: string;
  minDate?: Date;
  maxDate?: Date;
}

export class DateInputRowWrapper extends React.Component<DateInputRowWrapperProps> {
  public render() {
    return (
      <Field name={this.props.name} validate={validateDatum}>
        {({ field }: FieldProps<DateInputValue>) => (
          <DateInputRow
            {...field}
            label={this.props.label}
            data-component-id={`beratungsweiche-${field.name}`}
            onChange={
              /* tslint:disable-next-line */
              (date: DateInputValue) => this.props.form.setFieldValue(field.name, date)
            }
            onBlur={
              /* tslint:disable-next-line */
              (date: DateInputValue) => this.props.form.setFieldValue(field.name, date)
            }
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            error={getErrorMessage(this.props.form, field.name)}
          />
        )}
      </Field>
    );
  }
}
