import { GlobalTracking, LinkType } from '@eg/tracking';
import {
  BeratungsweicheTrackingObject,
  FehlerTrackingObject
} from '../types/TrackingObjects';

export class TrackingUtil {
  private globalTrackingInstance: GlobalTracking;
  private vorgaengerSeite: string;
  private linkName: string;
  private adviceOENumber: string;

  constructor(einbindungsPosition: string) {
    this.globalTrackingInstance = new GlobalTracking();

    this.vorgaengerSeite = 'vorgaengerSeiteUnknown';

    if (window.appEventData && window.appEventData.length >= 1) {
      // Je nachdem welches Skript schneller geladen ist,
      // findet man den pageName unter appEventData[0] oder appEventData[1]
      window.appEventData.forEach((appEvent: any) => {
        if (appEvent.page && appEvent.page.pageInfo) {
          this.vorgaengerSeite = appEvent.page.pageInfo.pageName;
        }
      });
    }

    this.linkName = einbindungsPosition + '|Beratungsweiche|';
    this.adviceOENumber = '';

    this.trackInteraction = this.trackInteraction.bind(this);
    this.trackError = this.trackError.bind(this);
    this.setAdviceOENumber = this.setAdviceOENumber.bind(this);
  }

  public setAdviceOENumber(adviceOENumber: string) {
    this.adviceOENumber = adviceOENumber;
  }

  public trackInteraction(elementTrackingId: string, event?: string) {
    const trackingObject = this.getBeratungsweichenTrackingObjekt();
    trackingObject.values.linkName += elementTrackingId;
    if (event) {
      trackingObject.event = event;
    }
    this.trackLink(trackingObject);
  }

  public trackError(FehlerArt: string) {
    const trackingObject = this.getFehlerTrackingObjekt();
    const FehlerUrl = window.location.href;
    trackingObject.values = { ...trackingObject.values, FehlerArt, FehlerUrl };
    this.trackLink(trackingObject);
  }

  private getBeratungsweichenTrackingObjekt(): BeratungsweicheTrackingObject {
    return {
      componentPath: 'ergo/gwtapps/components/beratungsweiche',
      event: '',
      values: {
        AdviceOENumber: this.adviceOENumber,
        NavigationsElement: '',
        VorgaengerSeite: this.vorgaengerSeite,
        WebsiteBereich: 'Beratungsweiche',
        linkName: this.linkName
      }
    };
  }

  private getFehlerTrackingObjekt(): FehlerTrackingObject {
    return {
      componentPath: 'ergo/gwtapps/components/beratungsweiche',
      event: 'FehlerSeite',
      values: {
        FehlerArt: '',
        FehlerUrl: '',
        VorgaengerSeite: this.vorgaengerSeite,
        WebsiteBereich: 'Beratungsweiche'
      }
    };
  }

  private trackLink(
    trackingObject: BeratungsweicheTrackingObject | FehlerTrackingObject
  ) {
    if (this.isBeratungsweicheTrackingObject(trackingObject)) {
      trackingObject.values.NavigationsElement = `${trackingObject.values.linkName}/${trackingObject.values.VorgaengerSeite}`;
    }

    if (process.env.NODE_ENV === 'development') {
      // tslint:disable-next-line:no-console
      console.log('trackClickEvent', trackingObject);
    }

    try {
      this.globalTrackingInstance.record(trackingObject, {
        defaultLinkType: LinkType.CustomLink
      });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(`Tracking error: ${e}`);
    }
  }

  private isBeratungsweicheTrackingObject(
    trackingObject: BeratungsweicheTrackingObject | FehlerTrackingObject
  ): trackingObject is BeratungsweicheTrackingObject {
    return (
      (trackingObject as BeratungsweicheTrackingObject).values.linkName !== undefined
    );
  }
}
