import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Beratungsweiche from './components/Beratungsweiche/Beratungsweiche';
import './index.css';

import config from 'envify/ConfigFactory';

ReactDOM.render(
  <Beratungsweiche />,
  document.getElementById(config.rootId) as HTMLElement
);
