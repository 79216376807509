import { Button } from '@eg/elements/Button';
import { ChevronLeftIcon } from '@eg/elements/components/Icons/ChevronLeftIcon';
import { ChevronRightIcon } from '@eg/elements/components/Icons/ChevronRightIcon';
import Radio from '@eg/elements/Radio';
import { AgentDetailData } from '@eg/vermittler-details';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as React from 'react';
import { validateAnrede, validateName } from '../../../../common/validation/validation';
import { GatewayClient } from '../../../../gateway/GatewayClient';
import { Vermittlerinformationen } from '../../../../types/Vermittlerinformationen';
import { DateInputRowWrapper } from '../../../wrapper/DateInputRowWrapper';
import { InputRowWrapper } from '../../../wrapper/InputRowWrapper';
import { RadioGroupRowWrapper } from '../../../wrapper/RadioGroupRowWrapper';
import { BeratungFormData } from '../BeratungFormData';

export interface PersonalStepProps {
  api: GatewayClient;
  formData: BeratungFormData;
  goToNextStep: (formData: BeratungFormData, agentDetailData: AgentDetailData) => void;
  goToStepBefore: () => void;
  trackInteraction: (trackingID: string, event?: string) => void;
  trackError: (fehlerArt: string) => void;
  setAdviceOENummer: (adviceOENummer: string) => void;
  vermittlerAnzeigen: boolean;
}

interface PersonalStepState {
  formData: BeratungFormData;
  axiosError: boolean;
  loading: boolean;
}

export class PersonalStep extends React.Component<PersonalStepProps, PersonalStepState> {
  public static STEP_NAME = 'Persönliches';

  constructor(props: PersonalStepProps) {
    super(props);

    this.onFailure = this.onFailure.bind(this);
    this.state = {
      axiosError: false,
      formData: this.props.formData,
      loading: false
    };
  }

  public render() {
    return (
      <div style={{ padding: '2em 0' }}>
        <h3>Bitte geben Sie hier Ihren Namen und Geburtsdatum ein:</h3>

        <Formik initialValues={this.props.formData} onSubmit={this.onSubmit}>
          {(form: FormikProps<BeratungFormData>) => (
            <Form>
              <div style={{ maxWidth: '700px', padding: '2em 0' }}>
                <RadioGroupRowWrapper
                  form={form}
                  name="anrede"
                  label="Anrede"
                  validate={validateAnrede}
                >
                  <Radio label="Herr" value="Herr" key="Herr" />
                  <Radio label="Frau" value="Frau" key="Frau" />
                </RadioGroupRowWrapper>

                <InputRowWrapper
                  form={form}
                  name="vorname"
                  validate={validateName}
                  placeholder="Vorname"
                  label="Vorname"
                  maxLength={31}
                />

                <InputRowWrapper
                  form={form}
                  name="nachname"
                  validate={validateName}
                  placeholder="Nachname"
                  label="Nachname"
                  maxLength={36}
                />

                <DateInputRowWrapper
                  form={form}
                  name="geburtsdatum"
                  label="Geburtsdatum"
                />
              </div>

              <Button
                type="submit"
                variant="primary"
                iconRight={ChevronRightIcon}
                disabled={this.state.loading}
                loading={this.state.loading}
              >
                Zu Ihrem Berater
              </Button>
              {this.state.axiosError && (
                <div className="ee_form-row ee_is-invalid">
                  <span role="alert" className="ee_form-row__hint">
                    Bei der Beratersuche ist ein Fehler aufgetreten, bitte versuchen Sie
                    es erneut.
                  </span>
                </div>
              )}
            </Form>
          )}
        </Formik>

        <br />

        <Button
          type="button"
          variant="text-link"
          iconLeft={ChevronLeftIcon}
          style={{ marginTop: '1.25em' }}
          onClick={this.props.goToStepBefore}
        >
          Zurück
        </Button>
      </div>
    );
  }

  private onSubmit = (
    formData: BeratungFormData,
    actions: FormikHelpers<BeratungFormData>
  ) => {
    if (this.props.vermittlerAnzeigen) {
      this.setState({ formData, axiosError: false, loading: true }, () =>
        this.props.api.getOeNummer(
          formData,
          (vermitterinformationen: Vermittlerinformationen) => {
            this.onOeNummer(vermitterinformationen, actions);
          },
          (error: AxiosError) => {
            this.onFailure(error, actions);
          }
        )
      );
    } else {
      this.setState({ formData, axiosError: false, loading: true }, () => {
        this.props.goToNextStep(this.state.formData, {});
      });
    }
  };

  private onOeNummer = (
    vermittlerinformationen: Vermittlerinformationen,
    actions: FormikHelpers<BeratungFormData>
  ) => {
    if (vermittlerinformationen.oeNummer) {
      this.props.api.getVermittlerDetail(
        vermittlerinformationen.oeNummer,
        this.onVermittlerDetail,
        (error: AxiosError) => {
          this.onFailure(error, actions);
        }
      );
      this.props.setAdviceOENummer(vermittlerinformationen.oeNummer);
      const newFormData = this.state.formData;
      newFormData.oeNummer = vermittlerinformationen.oeNummer;
      newFormData.pnr = vermittlerinformationen.pnr;
      newFormData.isBereitsKunde = vermittlerinformationen.istBereitsKunde;
      this.setState({ formData: newFormData });
    } else {
      this.onFailure(
        { config: {}, name: 'invalidOE', message: 'invalid oe nummer' },
        actions
      );
    }
  };

  private onVermittlerDetail = (oeNummer: string, agentDetailData: AgentDetailData) => {
    const formData: BeratungFormData = this.state.formData;
    formData.oeNummer = oeNummer;

    this.props.goToNextStep(formData, agentDetailData);
  };

  private onFailure = (error: AxiosError, actions: FormikHelpers<BeratungFormData>) => {
    this.setState({ axiosError: true, loading: false });
    actions.setSubmitting(false);
    this.props.trackError('Fehler beim Ermittlen der Vermittlerdaten.');
  };
}
