import { Button } from '@eg/elements/Button';
import { ChevronRightIcon } from '@eg/elements/components/Icons/ChevronRightIcon';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import {
  validateHausnummer,
  validateOrt,
  validatePlz,
  validateStrasse
} from '../../../../common/validation/validation';
import { FormRowElement } from '../../../wrapper/FormRowElement';
import { FormRowWrapper } from '../../../wrapper/FormRowWrapper';
import { BeratungFormData } from '../BeratungFormData';

export interface OrtStepProps {
  formData: BeratungFormData;
  goToNextStep: (formData: BeratungFormData) => void;
}

export class AddressStep extends React.Component<OrtStepProps> {
  public static STEP_NAME = 'Ort';

  public render() {
    return (
      <div style={{ padding: '2em 0' }}>
        <h3>Bitte geben Sie Ihre Adresse an.</h3>

        <Formik initialValues={this.props.formData} onSubmit={this.props.goToNextStep}>
          {(form: FormikProps<BeratungFormData>) => (
            <Form>
              <div style={{ maxWidth: '700px', padding: '2em 0' }}>
                <FormRowWrapper form={form} label="Straße/Nr." colspans={[2, 1]}>
                  <FormRowElement
                    form={form}
                    name="strasse"
                    placeholder="Straße"
                    maxLength={51}
                    validate={validateStrasse}
                  />
                  <FormRowElement
                    form={form}
                    name="hausnummer"
                    placeholder="Hausnr."
                    maxLength={10}
                    validate={validateHausnummer}
                  />
                </FormRowWrapper>

                <FormRowWrapper form={form} label="PLZ/Ort" colspans={[1, 2]}>
                  <FormRowElement
                    form={form}
                    name="plz"
                    placeholder="PLZ"
                    maxLength={5}
                    validate={validatePlz}
                  />
                  <FormRowElement
                    form={form}
                    name="ort"
                    placeholder="Ort"
                    maxLength={30}
                    validate={validateOrt}
                  />
                </FormRowWrapper>
              </div>
              <Button
                type="submit"
                variant="primary"
                iconRight={ChevronRightIcon}
                disabled={form.isSubmitting}
                loading={form.isSubmitting}
              >
                Weiter
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
