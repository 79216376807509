import { FormRow } from '@eg/elements/FormRow';
import { FormikProps } from 'formik';
import * as React from 'react';
import { getErrorMessage } from '../../common/errorHelper';
import { FormRowElement } from './FormRowElement';

export interface FormRowWrapperProps {
  form: FormikProps<any>;
  label: string;
  colspans:
    | number[]
    | {
        regular?: number[];
        medium?: number[];
        large?: number[];
      };
}

export class FormRowWrapper extends React.Component<FormRowWrapperProps> {
  constructor(props: FormRowWrapperProps) {
    super(props);
    this.getErrorMessageList = this.getErrorMessageList.bind(this);
  }

  public render() {
    return (
      <FormRow
        label={this.props.label}
        colspans={this.props.colspans}
        error={this.getErrorMessageList()}
      >
        {this.props.children}
      </FormRow>
    );
  }

  private getErrorMessageList() {
    if (this.props.children) {
      // @ts-ignore
      const errorMessages = this.props.children.map((element: FormRowElement) => {
        return getErrorMessage(this.props.form, element.props.name);
      });

      return this.renderErrorMessages(errorMessages);
    }

    return undefined;
  }

  private renderErrorMessages = (errorMessages: Array<string | undefined>) => {
    if (
      errorMessages.length === 0 ||
      errorMessages.filter(errorMessage => errorMessage !== undefined).length === 0
    ) {
      return undefined;
    }

    return (
      <ul>
        {errorMessages
          .filter(errorMessage => errorMessage !== undefined)
          .map((errorMessage, index) => (
            <li key={index}>{errorMessage}</li>
          ))}
      </ul>
    );
  };
}
