import * as React from 'react';

import { Provider, THEME } from '@eg/elements/Provider';
import { AgentDetailData, ContactingStatus } from '@eg/vermittler-details';
import { TrackingUtil } from '../../common/TrackingUtil';
import BeratungsPopup from '../BeratungsPopup/BeratungsPopup';
import BeratungspopupButton from '../BeratungspopupButton/BeratungspopupButton';
import { BeratungsweicheModal } from '../BeratungsweicheModal/BeratungsweicheModal';
import { CommunicationModuleImitation } from '../CommunicationModuleImitation/CommunicationModuleImitation';
import { RenderModalEventListener } from '../RenderModalEventListener/RenderModalEventListener';
import { getAemParams } from '../../common/windowConfig';
import { AemParams } from '../../types/EDWindow';

// tslint:disable-next-line:no-var-requires
require('@eg/elements/styles/ergo-one/styles.css');

interface BeratungsweicheState {
  popupOpen: boolean;
  modalOpen: boolean;
  agentDetailData?: AgentDetailData;
  contactingStatus?: ContactingStatus;
  trackingUtil: TrackingUtil;
}

class Beratungsweiche extends React.Component<{}, BeratungsweicheState> {
  aemParams: AemParams

  constructor(props: {}) {
    super(props);

    this.aemParams = getAemParams();

    this.state = {
      modalOpen: false,
      popupOpen: false,
      trackingUtil: new TrackingUtil(this.aemParams.trackingPosition ?? 'unbekannt')
    };
  }

  public render() {
    const beratungsweicheAnzeigen = !this.aemParams.isNotDisplayed;
    const alsBeratungsbuttonAnzeigen =
      beratungsweicheAnzeigen && !this.aemParams.isIntegrationInProductPage;
    const alsKommunikationsmodulAnzeigen =
      beratungsweicheAnzeigen && this.aemParams.isIntegrationInProductPage;
    const vermittlerAnzeigen = !this.aemParams.isBrokerNotDisplayed;
    const actionId = this.aemParams.actionId;

    return (
      <Provider
        theme={THEME['ergo-one']}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <RenderModalEventListener
          toggleModal={() => {
            this.toggleBeratungsweicheModal(true);
            this.state.trackingUtil.trackInteraction(
              'Auswahl|BeraterKontaktierenCta',
              'AdviceSwitchFindAgent'
            );
          }}
        />
        {alsBeratungsbuttonAnzeigen && (
          <>
            <BeratungspopupButton
              isButtonToggled={this.state.popupOpen}
              toggleButton={this.toggleButton}
            />
            {this.state.popupOpen && (
              <BeratungsPopup
                top={this.getPopupTop()}
                accentColor={this.getDarkGreyColor()}
                toggleBeratunsweicheModal={this.toggleBeratungsweicheModal}
                telefonieIconImageSrc={this.aemParams.telephonyIconSource}
                telefonieTelefonnummer={this.getPhoneNumberFromNavigation()}
                vermittlerIconImageSrc={this.aemParams.brokerIconSource}
                agentDetailData={this.state.agentDetailData}
                contactingStatus={this.state.contactingStatus}
                vertriebskanaele={this.aemParams.salesChannel}
                trackInteraction={this.state.trackingUtil.trackInteraction}
              />
            )}
          </>
        )}
        {alsKommunikationsmodulAnzeigen && (
          <CommunicationModuleImitation
            // tslint:disable-next-line:jsx-no-lambda
            buttonOnClick={() => {
              this.toggleBeratungsweicheModal(!this.state.modalOpen);
              this.state.trackingUtil.trackInteraction(
                'Auswahl|VermittlerSuchen',
                'AdviceSwitchFindAgent'
              );
            }}
            buttonText="Berater kontaktieren"
          />
        )}
        <BeratungsweicheModal
          open={this.state.modalOpen}
          onClose={this.closeBeratungsweicheModal}
          produkt={this.aemParams.product ?? ''}
          agentDetailData={this.state.agentDetailData}
          agentDetailUpdate={this.updateAgentDetail}
          trackingUtil={this.state.trackingUtil}
          updateContactingStatus={this.updateContactingStatus}
          backendDomain={this.aemParams.backendDomain}
          vermittlerAnzeigen={vermittlerAnzeigen}
          actionId={actionId}
        />
      </Provider>
    );
  }

  private toggleButton = () => {
    if (!this.state.popupOpen) {
      this.state.trackingUtil.trackInteraction('Nix', 'AdviceSwitchOpen');
    } else {
      this.state.trackingUtil.trackInteraction('SchliessenButton');
    }

    this.setState({ popupOpen: !this.state.popupOpen });
  };
  private toggleBeratungsweicheModal = (isModalOpen: boolean) =>
    this.setState({ modalOpen: isModalOpen });
  private closeBeratungsweicheModal = () => this.toggleBeratungsweicheModal(false);

  private updateContactingStatus = (contactingStatus: ContactingStatus) =>
    this.setState({ contactingStatus, popupOpen: false });

  private updateAgentDetail = (agentDetailData: AgentDetailData) => {
    this.setState({ ...{ agentDetailData } });
  };

  private getPopupTop(): number {
    const beratungspopupButton = document.getElementById('beratungsweiche-Popup-Button');

    if (beratungspopupButton) {
      return beratungspopupButton.getBoundingClientRect().bottom + 10;
    } else {
      return 200;
    }
  }

  private getDarkGreyColor(): string {
    const colors = require('@eg/elements/styles/ergo/colors.json');
    const themeColor = colors.find((color: any) => color.name === 'theme-darker-gray');

    return themeColor ? themeColor.value : '#8e0038';
  }

  private getPhoneNumberFromNavigation(): string {
    const navElements = document.getElementsByClassName(
      'aem-react-component NavigationHeaderComponent'
    );
    if (navElements && navElements.length > 0) {
      // @ts-ignore
      const dataset = navElements[0].dataset;
      if (dataset.params) {
        const params = JSON.parse(dataset.params);
        if (params.phoneNumber) {
          return params.phoneNumber;
        }
      }
    }
    return '0800 / 666 9000';
  }
}

export default Beratungsweiche;
