import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { getErrorMessage } from '../../common/errorHelper';
import { RadioValueType } from '@eg/elements/components/Radio/Radio.types';

export interface RadioGroupRowWrapperProps {
  form: FormikProps<any>;
  name: string;
  label: string;
  placeholder?: string;
  maxLength?: number;
  validate: (value: any) => string | Promise<void> | undefined;
  children?: JSX.Element[];
}

export class RadioGroupRowWrapper extends React.Component<RadioGroupRowWrapperProps> {
  public render() {
    return (
      <Field name={this.props.name} validate={this.props.validate}>
        {({ field }: FieldProps<RadioValueType>) => (
          <RadioGroupRow
            {...field}
            placeholder={this.props.placeholder}
            label={this.props.label}
            maxLength={this.props.maxLength}
            data-component-id={`beratungsweiche-${field.name}`}
            error={getErrorMessage(this.props.form, field.name)}
            defaultValue={field.value}
          >
            {this.props.children}
          </RadioGroupRow>
        )}
      </Field>
    );
  }
}
