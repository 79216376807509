import { Card } from '@eg/elements/Card';
import { LayoutBox } from '@eg/elements/LayoutBox';
import { Panel } from '@eg/elements/Panel';
import * as React from 'react';

import { AgentDetail, AgentDetailData, ContactingStatus } from '@eg/vermittler-details';
import { VERTRIEBSKANAELE } from '../../types/Vertriebskanaele';
import './BeratungsPopup.css';
import CardLayoutWrapper from './CardLayoutWrapper/CardLayoutWrapper';

export interface BeratungsPopupProps {
  accentColor: string;
  toggleBeratunsweicheModal: (isModalOpen: boolean) => void;
  top?: number;
  telefonieIconImageSrc?: string;
  telefonieTelefonnummer: string;
  vermittlerIconImageSrc?: string;
  agentDetailData?: AgentDetailData;
  contactingStatus?: ContactingStatus;
  vertriebskanaele: VERTRIEBSKANAELE;
  trackInteraction: (trackingID: string, event?: string) => void;
}

const telefontext = `Rufen Sie uns gebührenfrei &
täglich von 7 bis 24 Uhr an.`;

const vermittlertext = `Finden Sie hier Ihren ERGO Berater.
Wir vereinbaren gern einen
persönlichen Termin mit Ihnen.`;

const telephoneCardBodyElement = (telefonieTelefonnummer?: string): JSX.Element => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '99%'
        }}
      >
        <b>{telefonieTelefonnummer}</b>
        <p style={{ textAlign: 'center', maxWidth: '100%', margin: '5px 0 0 0' }}>
          {telefontext}
        </p>
      </div>
    </>
  );
};

const vermittlerCardBodyElement = (): JSX.Element => {
  return <div style={{ textAlign: 'center' }}>{vermittlertext}</div>;
};

class BeratungsPopup extends React.Component<BeratungsPopupProps> {
  public render() {
    return (
      <Panel
        style={{
          padding: '0',
          top: this.props.top
        }}
        className="beratungsweiche__popup-panel"
        variant="tertiary"
      >
        <p style={{ marginTop: '40px', textAlign: 'center' }}>
          <b>Benötigen Sie eine Beratung?</b>
          <br /> Wir sind gerne auch vor Ort für Sie da.
        </p>
        <LayoutBox
          className="beratungsweiche__layout-box"
          direction={{ regular: 'column', medium: 'row' }}
        >
          {this.props.vertriebskanaele !== VERTRIEBSKANAELE.VERMITTLER && (
            <Card
              data-component-id="beratungsweiche-popup-telefonie"
              accentColor={this.props.accentColor}
              className="beratungsweiche__card"
              padding="small"
            >
              <CardLayoutWrapper
                headerText={'Sofort:'}
                subheaderText={'Ihr direkter Kontakt zu uns'}
                pictureSrc={this.props.telefonieIconImageSrc}
                childrenCentered={true}
                buttonText="Jetzt Anrufen"
                data-components-id="beratunsweiche-popup-jetzt-anrufen"
                buttonFunction={this.telefonieOnClick}
              >
                {telephoneCardBodyElement(this.props.telefonieTelefonnummer)}
              </CardLayoutWrapper>
            </Card>
          )}
          {this.props.vertriebskanaele !== VERTRIEBSKANAELE.TELEFONIE && (
            <Card
              data-component-id="beratungsweiche-popup-vermittler"
              accentColor={this.props.accentColor}
              className={
                this.props.vertriebskanaele === VERTRIEBSKANAELE.TELEFONIE_UND_VERMITTLER
                  ? 'beratungsweiche__card beratungsweiche__card-right'
                  : 'beratungsweiche__card'
              }
              padding="small"
            >
              {this.props.agentDetailData ? (
                <CardLayoutWrapper
                  headerText="Ihr exklusiver Ansprechpartner:"
                  childrenCentered={false}
                >
                  <AgentDetail
                    agentDetailData={this.props.agentDetailData}
                    border={false}
                    contactingStatus={this.props.contactingStatus}
                    trackingCallback={this.vermittlerDetailTrackingCallback}
                  />
                </CardLayoutWrapper>
              ) : (
                <CardLayoutWrapper
                  headerText="Ihr exklusiver Ansprechpartner:"
                  pictureSrc={this.props.vermittlerIconImageSrc}
                  buttonText="Berater kontaktieren"
                  buttonFunction={this.vermittlerOnClick}
                  data-components-id="beratunsweiche-popup-vermittler-finden"
                  childrenCentered={true}
                >
                  {vermittlerCardBodyElement()}
                </CardLayoutWrapper>
              )}
            </Card>
          )}
        </LayoutBox>
      </Panel>
    );
  }

  private telefonieOnClick = () => {
    window.open('tel:' + this.props.telefonieTelefonnummer.replace(/\D/g, ''));
    this.props.trackInteraction('Auswahl|JetztAnrufen', 'AdviceSwitchCallNow');
  };

  private vermittlerOnClick = () => {
    this.props.toggleBeratunsweicheModal(true);
    this.props.trackInteraction('Auswahl|VermittlerSuchen', 'AdviceSwitchFindAgent');
  };

  private vermittlerDetailTrackingCallback = (elementId: string) => {
    this.props.trackInteraction('Auswahl|' + elementId);
  };
}

export default BeratungsPopup;
