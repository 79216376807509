import { InputRow } from '@eg/elements/InputRow';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { getErrorMessage } from '../../common/errorHelper';
import { BeratungFormData } from '../BeratungsweicheModal/steps/BeratungFormData';

export interface InputRowWrapperProps {
  form: FormikProps<any>;
  name: string;
  label: string;
  placeholder?: string;
  maxLength?: number;
  validate: (value: any) => string | Promise<void> | undefined;
}

export class InputRowWrapper extends React.Component<InputRowWrapperProps> {
  public render() {
    return (
      <Field name={this.props.name} validate={this.props.validate}>
        {({ field }: FieldProps<BeratungFormData>) => (
          <InputRow
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            placeholder={this.props.placeholder}
            label={this.props.label}
            maxLength={this.props.maxLength}
            data-component-id={`beratungsweiche-${field.name}`}
            error={getErrorMessage(this.props.form, field.name)}
          />
        )}
      </Field>
    );
  }
}
