import { DateInputValue } from '@eg/elements/DateInput';
import { addDays, isAfter, isBefore, isValid, parse, subYears } from 'date-fns';
import { convertDateToString } from '../../utils';

const onlyNumbers = /^\d+$/;

export const validatePlz = (plz: string): string | undefined => {
  if (plz.length === 5 && onlyNumbers.test(plz) && plz !== '00000') {
    return undefined;
  }
  return 'Bitte geben Sie eine gültige Postleitzahl ein.';
};

export const validateOrt = (ort: string): string | undefined => {
  const address = /^[0-9A-Za-zäüöÄÜÖ\u00c0-\u01ff ./)(-]+$/;
  if (address.test(ort)) {
    return undefined;
  }
  return 'Bitte geben Sie einen gültigen Ort ein.';
};

export const validateHausnummer = (hausnummer: string) => {
  const housenumber = /^\d+[a-zA-Z\d _.\-/]*$/;
  if (housenumber.test(hausnummer)) {
    return undefined;
  }
  return 'Bitte geben Sie eine gültige Hausnummer ein.';
};

export const validateStrasse = (strasse: string) => {
  const street = /^[0-9A-Za-z\u00c0-\u01ff'. -]+$/;
  if (street.test(strasse)) {
    return undefined;
  }
  return 'Bitte geben Sie eine gültige Straße ein.';
};

export const validateName = (name: string): string | undefined => {
  const onlyLetters = /^[a-zA-Z\u00c0-\u01ff]+[a-zA-Z\u00c0-\u01ff -.]+$/;
  if (onlyLetters.test(name)) {
    return undefined;
  }
  return 'Bitte geben Sie einen gültigen Namen ein.';
};

export const validateAnrede = (anrede: string): string | undefined => {
  if (anrede !== 'Herr' && anrede !== 'Frau') {
    return 'Bitte wählen Sie Ihre gewünschte Anrede.';
  }
  return undefined;
};

export const validateDatum = (datum: DateInputValue) => {
  if (datum && datum.year && datum.month && datum.day) {
    const birthdate = parse(
      `${datum.year}-${datum.month}-${datum.day}`,
      'yyyy-MM-dd',
      new Date()
    );
    const lastValidDate = subYears(new Date(), 18);
    const lastValidDateString = convertDateToString(addDays(lastValidDate, 1));

    const firstValidDate = subYears(new Date(), 100);
    const firstValidDateString = convertDateToString(firstValidDate);

    if (isValid(birthdate)) {
      if (isBefore(birthdate, firstValidDate)) {
        return `Bitte geben Sie ein Datum nach dem ${firstValidDateString} an.`;
      } else if (isAfter(birthdate, lastValidDate)) {
        return `Bitte geben Sie ein Datum vor dem ${lastValidDateString} an.`;
      } else {
        return undefined;
      }
    }
  }
  return 'Bitte geben Sie ein gültiges Datum an.';
};

export const validateVorwahl = (vorwahl: string) => {
  if (
    !vorwahl ||
    vorwahl.length < 3 ||
    vorwahl.length > 5 ||
    !onlyNumbers.test(vorwahl)
  ) {
    return 'Bitte geben Sie eine gültige Vorwahl ein.';
  }

  if (!vorwahl.startsWith('0')) {
    return 'Bitte geben Sie die Vorwahl mit führender "0" ein.';
  }
};

export const validateRufnummer = (rufnummer: string) => {
  if (onlyNumbers.test(rufnummer)) {
    return undefined;
  }
  return 'Bitte geben Sie eine gültige Rufnummer ein.';
};

export const validateEmail = (email: string): string | undefined => {
  // regex from https://emailregex.com/ with 2 escapes removed because ESLint complained that they are unneccessary
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email && pattern.test(email.trim())) {
    return undefined;
  }
  return 'Bitte geben Sie eine gültige E-Mail ein.';
};
