const config = {
  auth: process.env.REACT_APP_TECH_USER
    ? {
        // used for local dev
        username: process.env.REACT_APP_TECH_USER,
        password: process.env.REACT_APP_TECH_PASS || ''
      }
    : undefined
};

export default config;
