import * as React from 'react';

import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { ManIcon } from '@eg/elements/components/Icons';
import './CommunicationModuleImitation.css';

export interface CommunicationModuleImitationProps {
  buttonOnClick: () => void;
  buttonText: string;
}

export class CommunicationModuleImitation extends React.Component<
  CommunicationModuleImitationProps
> {
  public render() {
    return (
      <div style={{ maxWidth: '272px', width: '100%', backgroundColor: 'white' }}>
        <Card className="beratungsweiche__communication-module">
          <ManIcon className="beratungsweiche__communication-module__icon" />
          <p className="beratungsweiche__communication-module__title">Ihr ERGO Berater</p>
          <p className="beratungsweiche__communication-module__text">
            Gleich Termin vereinbaren!
          </p>
          <div className="beratungsweiche__communication-module__buttonwrapper">
            <Button
              onClick={this.props.buttonOnClick}
              variant="primary"
              className="beratungsweiche__communication-module__button"
            >
              {this.props.buttonText}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}
