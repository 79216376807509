import config from '../envify/ConfigFactory';
import { AemParams } from 'types';

const convertToBoolean = (param?: string) => param === 'true';

export const getAemParams = (): AemParams => {
  const aemParams = window.params[config.appId];

  const appConfig = {
    telephonyIconSource: aemParams.telefonieIconSource,
    brokerIconSource: aemParams.vermittlerIconSource,
    product: aemParams.produkt,
    salesChannel: aemParams.vertriebskanaele,
    isIntegrationInProductPage: convertToBoolean(aemParams.einbindungInProduktseite),
    isNotDisplayed: convertToBoolean(aemParams.nichtAnzeigen),
    isBrokerNotDisplayed: convertToBoolean(aemParams.vermittlerNichtAnzeigen),
    actionId: aemParams.actionId,
    trackingPosition: aemParams.trackingPosition,
    backendDomain: aemParams.envs[0].url || undefined
  };

  console.log(`App configuration for appId: ${config.appId} with root id: ${config.rootId}`);
  console.log(appConfig);

  return appConfig;
};
