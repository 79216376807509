import { Input } from '@eg/elements/Input';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { getErrorMessage, hasError } from '../../common/errorHelper';
import { BeratungFormData } from '../BeratungsweicheModal/steps/BeratungFormData';

export interface FormRowElementProps {
  form: FormikProps<any>;
  name: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  validate: (value: any) => string | Promise<void> | undefined;
}

export class FormRowElement extends React.Component<FormRowElementProps> {
  public render() {
    return (
      <Field name={this.props.name} validate={this.props.validate}>
        {({ field }: FieldProps<BeratungFormData>) => (
          <Input
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            placeholder={this.props.placeholder}
            maxLength={this.props.maxLength}
            data-component-id={`beratungsweiche-${field.name}`}
            error={hasError(getErrorMessage(this.props.form, field.name))}
          />
        )}
      </Field>
    );
  }
}
