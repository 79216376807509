import {
  concatIfFirstElementNotFalsy,
  concatIfSecondElementNotFalsy
} from '@eg/vermittler-details';
import { BeratungFormData } from '../components/BeratungsweicheModal/steps/BeratungFormData';
import { EDCookie } from '../types/EDCookie';
import { Lead } from '../types/Lead';

export class LeadMapper {
  public static mapFormDataToLead = (
    formData: BeratungFormData,
    produkt: string,
    erlaubnisAnruf: boolean,
    erlaubnisEmail: boolean,
    cookies: EDCookie,
    actionId?: string
  ): Lead => {
    const {
      anrede,
      vorname,
      nachname,
      strasse,
      hausnummer,
      plz,
      ort,
      vorwahl,
      rufnummer,
      geburtsdatum,
      email,
      oeNummer,
      pnr
    } = formData;

    const telefon = concatIfSecondElementNotFalsy(vorwahl, rufnummer, '/');
    const strasseHausnummer = concatIfFirstElementNotFalsy(strasse, hausnummer, ' ');
    const isBereitsKunde = formData.isBereitsKunde ? formData.isBereitsKunde : false;

    return {
      anliegen: `Beratung zu Produkt ${produkt} von ${window.location.origin}${window.location.pathname}`,
      anrede,
      email: email ? email.trim() : '',
      erlaubnisAnruf,
      erlaubnisEmail,
      geburtsdatum: [geburtsdatum.year, geburtsdatum.month, geburtsdatum.day].join('-'),
      nachname,
      oeNummer: oeNummer ? oeNummer : '',
      ort,
      plz,
      strasse: strasseHausnummer ? strasseHausnummer : '',
      telefon: telefon ? telefon : '',
      thema: produkt,
      vorname,
      isBereitsKunde,
      pnr,
      cookies,
      actionId
    };
  };
}
