import { Button } from '@eg/elements/Button';
import * as React from 'react';
import './CardLayoutWrapper.css';

export interface CardLayoutWrapperProps {
  headerText?: string;
  subheaderText?: string;
  pictureSrc?: string;
  'data-components-id'?: string;
  buttonText?: string;
  buttonFunction?: () => void;
  children?: JSX.Element | JSX.Element[];
  childrenCentered: boolean;
}

const CardLayoutWrapper = (props: CardLayoutWrapperProps) => {
  const {
    headerText,
    subheaderText,
    pictureSrc,
    buttonText,
    buttonFunction,
    children,
    childrenCentered
  } = props;
  return (
    <div className={'cardlayoutwrapper__container'}>
      <div className={'cardlayoutwrapper__header'}>
        <b>{headerText}</b>
      </div>
      {subheaderText ? (
        <div className={'cardlayoutwrapper__sub-header'}>{subheaderText}</div>
      ) : (
        <br />
      )}
      {pictureSrc && (
        <div className={'cardlayoutwrapper__picture-container'}>
          <div style={{ height: '100px', width: '100px' }}>
            <img
              className={'cardlayoutwrapper__picture'}
              src={pictureSrc}
              alt={'Ihr Berater'}
              data-component-id={
                props['data-components-id']
                  ? props['data-components-id'] + '-picture'
                  : undefined
              }
            />
          </div>
        </div>
      )}
      {children && (
        <div
          className={
            childrenCentered
              ? 'cardlayoutwrapper__body-element-container-centered'
              : 'cardlayoutwrapper__body-element-container'
          }
        >
          {children}
        </div>
      )}
      {buttonText && buttonFunction && (
        <div className={'cardlayoutwrapper__button-container'}>
          <Button
            variant="primary"
            data-component-id={
              props['data-components-id']
                ? props['data-components-id'] + '-button'
                : undefined
            }
            onClick={buttonFunction}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CardLayoutWrapper;
