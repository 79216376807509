import { FormikProps } from 'formik';
import { BeratungFormData } from '../components/BeratungsweicheModal/steps/BeratungFormData';

export const getErrorMessage = (
  form: FormikProps<BeratungFormData>,
  field: string
): string | undefined => {
  // TODO better fix (not as any) for:
  // Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FormikTouched<BeratungFormData>'
  if (
    ((form.touched as any)[field] || form.isSubmitting) &&
    (form.errors as any)[field]
  ) {
    return (form.errors as any)[field];
  }
  return undefined;
};

export const hasError = (...errorMessages: Array<string | undefined>): boolean => {
  return errorMessages.filter(errorMessage => errorMessage !== undefined).length > 0;
};
