import CloseIcon from '@eg/elements/components/Icons/CloseIcon';
import LayoutBox from '@eg/elements/LayoutBox';
import { Modal } from '@eg/elements/Modal';
import { StepNavigation } from '@eg/elements/StepNavigation';
import { AgentDetailData, ContactingStatus } from '@eg/vermittler-details';
import axios from 'axios';
import * as React from 'react';
import { TrackingUtil } from '../../common/TrackingUtil';
import config from '../../envify/ConfigFactory';
import { createClient, GatewayClient } from '../../gateway/GatewayClient';
import './BeratungsweicheModal.css';
import { AddressStep } from './steps/address/AddressStep';
import { AgentDetailStep } from './steps/agentDetail/AgentDetailStep';
import { BeratungFormData } from './steps/BeratungFormData';
import { PersonalStep } from './steps/personal/PersonalStep';
import { StepContainer } from './steps/StepContainer';
import { FormikProps } from 'formik';

export interface BeratungsweicheModalProps {
  open: boolean;
  onClose: () => void;
  produkt: string;
  agentDetailData?: AgentDetailData;
  agentDetailUpdate: (agentDetailData: AgentDetailData) => void;
  updateContactingStatus: (contactingStatus: ContactingStatus) => void;
  trackingUtil: TrackingUtil;
  backendDomain?: string;
  vermittlerAnzeigen: boolean;
  actionId?: string;
}

interface BeratungsweicheModalState {
  activeStep: string;
  beratungFormData: BeratungFormData;
}

const createApiClient = (
  produkt: string,
  backendDomain?: string,
  actionId?: string
): GatewayClient => {
  let url = config.baseGatewayUrl;
  if (backendDomain) {
    url = `${backendDomain}${url}`;
  }
  axios.defaults.timeout = 20000;
  return createClient(axios, url, produkt);
};

export class BeratungsweicheModal extends React.Component<
  BeratungsweicheModalProps,
  BeratungsweicheModalState
> {
  private steps = [
    AddressStep.STEP_NAME,
    PersonalStep.STEP_NAME,
    AgentDetailStep.STEP_NAME
  ];
  private initialFormData: BeratungFormData = {
    anrede: '',
    email: '',
    geburtsdatum: {},
    hausnummer: '',
    nachname: '',
    ort: '',
    plz: '',
    rufnummer: '',
    strasse: '',
    vorname: '',
    vorwahl: ''
  };

  private API: GatewayClient;

  constructor(props: BeratungsweicheModalProps) {
    super(props);
    this.state = {
      activeStep: this.steps[0],
      beratungFormData: this.initialFormData
    };
    this.API = createApiClient(this.props.produkt, this.props.backendDomain);
  }

  public render() {
    return (
      <Modal
        open={this.props.open}
        viewport="fixed"
        id="beratungsweicheModal"
        extendedWidth
      >
        <LayoutBox flex={[1, 0]}>
          <StepNavigation
            activeStepIndex={this.getStepIndex(this.state.activeStep)}
            steps={this.steps}
            goToStepByIndex={this.trackedGoToStep}
          />
          <CloseIcon
            width={32}
            height={32}
            style={{ cursor: 'pointer' }}
            onClick={this.closeIconOnClick}
          />
        </LayoutBox>

        <StepContainer isActiveStep={this.isActiveStep(AddressStep.STEP_NAME)}>
          <AddressStep
            formData={this.state.beratungFormData}
            goToNextStep={this.goToNextStep}
          />
        </StepContainer>

        <StepContainer isActiveStep={this.isActiveStep(PersonalStep.STEP_NAME)}>
          <PersonalStep
            api={this.API}
            formData={this.state.beratungFormData}
            goToNextStep={this.goToNextStep}
            goToStepBefore={this.goToStepBefore}
            trackInteraction={this.props.trackingUtil.trackInteraction}
            trackError={this.props.trackingUtil.trackError}
            setAdviceOENummer={this.props.trackingUtil.setAdviceOENumber}
            vermittlerAnzeigen={this.props.vermittlerAnzeigen}
          />
        </StepContainer>

        <StepContainer isActiveStep={this.isActiveStep(AgentDetailStep.STEP_NAME)}>
          <AgentDetailStep
            api={this.API}
            formData={this.state.beratungFormData}
            agentDetailData={this.props.agentDetailData}
            produkt={this.props.produkt}
            closeModal={this.props.onClose}
            updateContactingStatus={this.props.updateContactingStatus}
            trackInteraction={this.props.trackingUtil.trackInteraction}
            trackError={this.props.trackingUtil.trackError}
            vermittlerAnzeigen={this.props.vermittlerAnzeigen}
            actionId={this.props.actionId}
          />
        </StepContainer>
      </Modal>
    );
  }

  private isActiveStep(actualStep: string) {
    return this.state.activeStep === actualStep;
  }

  private getStepIndex = (stepName: string): number => {
    return this.steps.findIndex(element => element === stepName);
  };

  private goToStep = (index: number) => {
    this.setState({
      activeStep: this.steps[index]
    });
  };

  private trackedGoToStep = (index: number) => {
    const fromStep =
      this.state.activeStep === PersonalStep.STEP_NAME
        ? 'VermittlerPersoenliches'
        : 'VermittlerFeedback';
    const toStep =
      this.steps[index] === AddressStep.STEP_NAME
        ? 'ZurueckTabOrt'
        : 'ZurueckTabPersoehnliches';

    this.props.trackingUtil.trackInteraction(`${fromStep}|${toStep}`);

    this.goToStep(index);
  };

  private goToNextStep = (
    beratungFormData: BeratungFormData,
    agentDetailData?: AgentDetailData
  ) => {
    this.setState({ beratungFormData });
    // formik sends his objects here - we need to filter that
    if (
      agentDetailData &&
      typeof (agentDetailData as FormikProps<any>).submitForm !== 'function'
    ) {
      this.props.agentDetailUpdate(agentDetailData);
    }

    const activeStepIndex = this.getStepIndex(this.state.activeStep);
    const nextStepIndex = activeStepIndex + 1;

    this.trackNextStep();

    this.goToStep(nextStepIndex);
  };

  private goToStepBefore = () => {
    const activeStepIndex = this.getStepIndex(this.state.activeStep);
    const nextStepIndex = activeStepIndex - 1;

    this.trackStepBefore();

    this.goToStep(nextStepIndex);
  };

  private trackNextStep() {
    switch (this.state.activeStep) {
      case AddressStep.STEP_NAME:
        this.props.trackingUtil.trackInteraction('VermittlerFindenWohnort|WeiterButton');
        break;
      case PersonalStep.STEP_NAME:
        this.props.trackingUtil.trackInteraction('VermittlerPersoenliches|WeiterButton');
        break;
      default:
        throw new Error('Tried to track next Step for unknown step.');
    }
  }

  private trackStepBefore() {
    switch (this.state.activeStep) {
      case PersonalStep.STEP_NAME:
        this.props.trackingUtil.trackInteraction('VermittlerPersoenliches|ZurueckLink');
        break;
      default:
        throw new Error('Tried to track next Step for unknown step.');
    }
  }

  private closeIconOnClick = () => {
    this.props.onClose();
    this.props.trackingUtil.trackInteraction('SchliessenX');
  };
}
