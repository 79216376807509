import { Button } from '@eg/elements/Button';
import { ChevronRightIcon } from '@eg/elements/components/Icons';
import Input from '@eg/elements/Input';
import { LayoutBox } from '@eg/elements/LayoutBox';
import { AgentDetail, AgentDetailData, ContactingStatus } from '@eg/vermittler-details';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as React from 'react';
import { getCookies } from '../../../../common/cookieUtil';
import { getErrorMessage, hasError } from '../../../../common/errorHelper';
import { LeadMapper } from '../../../../common/LeadMapper';
import {
  validateEmail,
  validateRufnummer,
  validateVorwahl
} from '../../../../common/validation/validation';
import { GatewayClient } from '../../../../gateway/GatewayClient';
import { Lead } from '../../../../types/Lead';
import { BeratungFormData } from '../BeratungFormData';
import './AgentDetailStep.css';

export interface AgentDetailStepProps {
  api: GatewayClient;
  formData: BeratungFormData;
  agentDetailData?: AgentDetailData;
  produkt: string;
  closeModal: () => void;
  updateContactingStatus: (contactingStatus: ContactingStatus) => void;
  trackInteraction: (trackingID: string, event?: string) => void;
  trackError: (fehlerArt: string) => void;
  vermittlerAnzeigen: boolean;
  actionId?: string;
}

interface AgentDetailStepState {
  formData: BeratungFormData;
  showView: View;
}

enum View {
  TELEFON,
  EMAIL,
  FEEDBACK
}

const telefonerrors = (form: any) => {
  return (
    <div className="ee_form-row ee_is-invalid">
      <span role="alert" className="ee_form-row__hint">
        <ul>
          {hasError(getErrorMessage(form, 'vorwahl')) && (
            <li>{getErrorMessage(form, 'vorwahl')}</li>
          )}
          {hasError(getErrorMessage(form, 'rufnummer')) && (
            <li>{getErrorMessage(form, 'rufnummer')}</li>
          )}
        </ul>
      </span>
    </div>
  );
};

export class AgentDetailStep extends React.Component<
  AgentDetailStepProps,
  AgentDetailStepState
> {
  public static STEP_NAME = 'Ihr Berater';

  constructor(props: AgentDetailStepProps) {
    super(props);
    this.state = {
      formData: props.formData,
      showView: View.TELEFON
    };
  }

  public render() {
    return (
      <div className="beratungsweiche__modal-agent-detail-step-container">
        {this.props.agentDetailData && this.props.vermittlerAnzeigen && (
          <div className="beratungsweiche__modal-agent-detail-step-agentDetails">
            <AgentDetail
              agentDetailData={this.props.agentDetailData}
              trackingCallback={this.vermittlerDetailsTrackingCallback}
            />
          </div>
        )}
        <div className="beratungsweiche__modal-agent-detail-step-agentIntroduction">
          <p>
            {`Sehr ${this.props.formData.anrede === 'Herr' ? 'geehrter' : 'geehrte'} ${
              this.props.formData.anrede
            } ${this.props.formData.nachname},`}
          </p>
          <p>
            {this.getVermittlertext(
              this.props.vermittlerAnzeigen,
              this.props.agentDetailData
            )}
          </p>
          <br />
        </div>
        {this.state.showView === View.TELEFON && this.renderRueckrufAnfordern()}
        {this.state.showView === View.EMAIL && this.renderEmailAnfordern()}
        {this.state.showView === View.FEEDBACK && this.renderFeedback()}
      </div>
    );
  }

  private renderRueckrufAnfordern = () => {
    let rueckrufText;
    if (this.props.vermittlerAnzeigen) {
      rueckrufText =
        this.props.agentDetailData && this.props.agentDetailData.anrede
          ? ' ich rufe '
          : ' wir rufen ';
    } else {
      rueckrufText = ' ein ERGO Berater ruft ';
    }
    return (
      <div className="beratungsweiche__modal-agent-detail-step-callToAction">
        <p>
          Klicken Sie hier und
          {rueckrufText}
          Sie so schnell wie möglich zurück.
        </p>
        <Formik initialValues={this.props.formData} onSubmit={this.onSubmit}>
          {(form: FormikProps<BeratungFormData>) => (
            <Form>
              <label>Telefon-Nummer:&#42;</label>
              <LayoutBox flex={[1, 0, 2]} style={{ maxWidth: '290px' }}>
                <Field name="vorwahl" validate={validateVorwahl}>
                  {({ field }: FieldProps<BeratungFormData>) => (
                    <Input
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      data-component-id={`beratungsweiche-${field.name}`}
                      error={hasError(getErrorMessage(form, field.name))}
                    />
                  )}
                </Field>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    fontSize: '1.6em',
                    height: '100%'
                  }}
                >
                  <span>/</span>
                </div>
                <Field name="rufnummer" validate={validateRufnummer}>
                  {({ field }: FieldProps<BeratungFormData>) => (
                    <Input
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      data-component-id={`beratungsweiche-${field.name}`}
                      error={hasError(getErrorMessage(form, field.name))}
                    />
                  )}
                </Field>
              </LayoutBox>
              {hasError(
                getErrorMessage(form, 'vorwahl'),
                getErrorMessage(form, 'rufnummer')
              ) && telefonerrors(form)}
              <Button
                type="submit"
                variant="primary"
                disabled={form.isSubmitting}
                loading={form.isSubmitting}
                style={{ marginTop: '1.5em' }}
              >
                Rückruf anfordern
              </Button>
            </Form>
          )}
        </Formik>
        <Button
          type="button"
          variant="text-link"
          iconLeft={ChevronRightIcon}
          style={{ marginTop: '1.25em' }}
          onClick={this.onEmail}
        >
          Kontaktieren Sie mich lieber per E-Mail.
        </Button>
        {this.renderDatenschutz()}
      </div>
    );
  };

  private renderEmailAnfordern = () => {
    let emailText;
    if (this.props.vermittlerAnzeigen) {
      emailText =
        this.props.agentDetailData && this.props.agentDetailData.anrede
          ? ' ich melde mich '
          : ' wir melden uns ';
    } else {
      emailText = ' ein ERGO Berater meldet sich ';
    }

    return (
      <div className="beratungsweiche__modal-agent-detail-step-callToAction">
        <p>
          Klicken Sie hier und
          {emailText}
          bei Ihnen so schnell wie möglich per E-Mail.
        </p>
        <Formik initialValues={this.props.formData} onSubmit={this.onSubmit}>
          {(form: FormikProps<BeratungFormData>) => (
            <Form>
              <label>E-Mail:&#42;</label>
              <Field name="email" validate={validateEmail}>
                {({ field }: FieldProps<BeratungFormData>) => (
                  <Input
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    data-component-id={`beratungsweiche-${field.name}`}
                    error={hasError(getErrorMessage(form, field.name))}
                    style={{ maxWidth: '290px' }}
                  />
                )}
              </Field>
              {hasError(getErrorMessage(form, 'email')) && (
                <div className="ee_form-row ee_is-invalid">
                  <span role="alert" className="ee_form-row__hint">
                    {getErrorMessage(form, 'email')}
                  </span>
                </div>
              )}
              <Button
                type="submit"
                variant="primary"
                disabled={form.isSubmitting}
                loading={form.isSubmitting}
                style={{ marginTop: '1.5em' }}
              >
                E-Mail anfordern
              </Button>
            </Form>
          )}
        </Formik>
        <Button
          type="button"
          variant="text-link"
          iconLeft={ChevronRightIcon}
          style={{ marginTop: '1.25em' }}
          onClick={this.onRueckruf}
        >
          Kontaktieren Sie mich lieber per Telefon
        </Button>
        {this.renderDatenschutz()}
      </div>
    );
  };

  private renderFeedback = () => {
    return (
      <div className="beratungsweiche__modal-agent-detail-step-callToAction">
        <LayoutBox flex={[0, 1]} direction="column">
          <LayoutBox flex={[0, 1]} direction={{ regular: 'column', large: 'row' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              {this.props.agentDetailData && this.props.agentDetailData.anrede ? (
                <p style={{ alignSelf: 'center' }}>
                  Vielen Dank für Ihr Vertrauen.
                  <br />
                  Gern berate ich Sie bei Ihren Versicherungsfragen und werde mich zeitnah
                  bei Ihnen melden.
                </p>
              ) : (
                <p style={{ alignSelf: 'center' }}>
                  Vielen Dank für Ihr Vertrauen.
                  <br />
                  Gern beraten wir Sie bei Ihren Versicherungsfragen und werden uns
                  zeitnah bei Ihnen melden.
                </p>
              )}
            </div>
          </LayoutBox>

          <Button onClick={this.props.closeModal} variant="primary">
            Schließen
          </Button>
        </LayoutBox>
      </div>
    );
  };

  private renderDatenschutz = () => {
    return (
      <p>
        * Datenschutzhinweis: Ich bin damit einverstanden, dass meine persönlichen Daten
        zur Ermittlung eines Beraters von der ERGO Beratung und Vertrieb AG verarbeitet
        werden. Mein Kontaktwunsch wird entweder an den Berater weitergeleitet, der mich
        bereits betreut oder an einen Berater in meiner Nähe.
      </p>
    );
  };

  private onRueckruf = () => {
    this.setState({ showView: View.TELEFON });
    this.props.trackInteraction('VermittlerFeedback|LieberPerTelefon');
  };

  private onEmail = () => {
    this.setState({ showView: View.EMAIL });
    this.props.trackInteraction('VermittlerFeedback|LieberPerEmail');
  };

  private onSubmit = (
    formData: BeratungFormData,
    actions: FormikHelpers<BeratungFormData>
  ) => {
    switch (this.state.showView) {
      case View.EMAIL:
        this.props.updateContactingStatus(ContactingStatus.EMAIL_REQUEST);
        this.trackSubmit('EmailAnfordern');
        break;
      case View.TELEFON:
        this.props.updateContactingStatus(ContactingStatus.CALLBACK_REQUEST);
        this.trackSubmit('RueckrufAnfordern');
        break;
    }
    this.setState({ formData }, () => {
      this.createLead(this.state.showView, actions);
    });
  };

  private trackSubmit = (contactMethod: 'RueckrufAnfordern' | 'EmailAnfordern') => {
    if (contactMethod === 'RueckrufAnfordern') {
      this.props.trackInteraction(
        'VermittlerFeedback|RueckrufAnfordern',
        'AdviceSwitchContactAgentCallback'
      );
    } else {
      this.props.trackInteraction(
        'VermittlerFeedback|EmailAnfordern',
        'AdviceSwitchContactAgentEmail'
      );
    }
  };

  private getVermittlertext(
    vermittlerAnzeigen: boolean,
    agentDetails?: AgentDetailData
  ): string {
    if (vermittlerAnzeigen) {
      if (agentDetails && agentDetails.anrede) {
        switch (agentDetails.anrede) {
          case 'Frau':
            return `ich bin ${agentDetails.vorname} ${agentDetails.nachname}, Ihre persönliche Beraterin bei ERGO.`;
          case 'Herr':
            return `ich bin ${agentDetails.vorname} ${agentDetails.nachname}, Ihr persönlicher Berater bei ERGO.`;
          default:
            return `wir sind ${this.getAgenturtext(agentDetails)}
          Ihre ERGO-Versicherungsagentur.`;
        }
      } else {
        return `wir sind ${this.getAgenturtext(agentDetails)}
      Ihre ERGO-Versicherungsagentur.`;
      }
    } else {
      return '';
    }
  }

  private getAgenturtext = (agentDetails?: AgentDetailData): string => {
    if (agentDetails && agentDetails.vorname && agentDetails.nachname) {
      return `die ${agentDetails.vorname} ${agentDetails.nachname}, `;
    } else {
      return '';
    }
  };

  private createLead = (view: View, actions: FormikHelpers<BeratungFormData>) => {
    const erlaubnisAnruf = View.TELEFON === view;
    const erlaubnisEmail = View.EMAIL === view;

    const lead: Lead = LeadMapper.mapFormDataToLead(
      this.state.formData,
      this.props.produkt,
      erlaubnisAnruf,
      erlaubnisEmail,
      getCookies(),
      this.props.actionId
    );

    const onSuccess = () => this.setState({ showView: View.FEEDBACK });
    const onFailure = () => {
      const errorField = View.TELEFON === view ? 'rufnummer' : 'email';
      const errorMessage =
        'Ihre Kontaktanfrage konnte leider nicht übermittelt werden, bitte versuchen Sie es erneut.';
      actions.setFieldError(errorField, errorMessage);
      actions.setSubmitting(false);
      this.props.trackError('Leadübertragung an EMI gescheitert.');
    };
    this.props.api.sendLead(lead, onSuccess, onFailure);
  };

  private vermittlerDetailsTrackingCallback = (elementId: string) => {
    this.props.trackInteraction('VermittlerFeedback|' + elementId);
  };
}
