import { AxiosError, AxiosResponse as Response } from 'axios';
import { BeratungFormData } from '../components/BeratungsweicheModal/steps/BeratungFormData';
import { Vermittlerinformationen } from '../types/Vermittlerinformationen';
import { FetchService } from './FetchService';
import config from '../config';

export interface GatewayClient {
  getOeNummer: (
    formData: BeratungFormData,
    successHandler: (vermittlerinformationen: Vermittlerinformationen) => void,
    failHandler: (error: AxiosError) => void
  ) => Promise<any>;
  getVermittlerDetail: (
    oenummer: string,
    successHandler: (oeNummer: string, vermittlerDetail: any) => void,
    failHandler: (error: AxiosError) => void
  ) => void;
  sendLead: (
    lead: any,
    successHandler: () => void,
    failHandler: (error: AxiosError) => void
  ) => void;
}

const auth = config.auth
  ? {
      username: config.auth.username,
      password: config.auth.password
    }
  : undefined;

export const createClient = (
  fetchService: FetchService,
  baseGatewayUrl: string,
  produktwunsch: string
): GatewayClient => {
  return {
    getOeNummer: async (formData, successHandler, failHandler) => {
      try {
        const params = {
          anrede: formData.anrede,
          geburtsdatum: [
            formData.geburtsdatum.day,
            formData.geburtsdatum.month,
            formData.geburtsdatum.year
          ].join('.'),
          hausnummer: formData.hausnummer,
          nachname: formData.nachname,
          ort: formData.ort,
          postleitzahl: formData.plz,
          strasse: formData.strasse,
          vorname: formData.vorname,
          produktwunsch
        };

        const response: Response = await fetchService.get(
          `${baseGatewayUrl}/vermittler/oenummer`,
          {
            params,
            auth
          }
        );
        successHandler(response.data);
      } catch (e) {
        failHandler(e);
      }
    },
    getVermittlerDetail: async (oenummer, successHandler, failHandler) => {
      try {
        const params = {
          oenummer
        };
        const response: Response = await fetchService.get(
          `${baseGatewayUrl}/vermittler/detail`,
          {
            params,
            auth
          }
        );
        successHandler(oenummer, response.data);
      } catch (e) {
        failHandler(e);
      }
    },
    sendLead: async (lead, successHandler, failHandler) => {
      try {
        await fetchService.post(`${baseGatewayUrl}/lead`, lead, { timeout: 10000 });
        successHandler();
      } catch (e) {
        failHandler(e);
      }
    }
  };
};
