import Button from '@eg/elements/Button';
import { ConsultantIcon } from '@eg/elements/components/Icons';
import { CloseIcon } from '@eg/elements/Icons/CloseIcon';
import * as React from 'react';
import './BeratungspopupButton.css';

export interface BeratungspopupButtonProps {
  isButtonToggled: boolean;
  toggleButton: () => void;
}

class BeratungspopupButton extends React.Component<BeratungspopupButtonProps> {
  private ICON_SIZE = 24;

  public render() {
    return (
      <div
        id="beratungsweiche-Popup-Button"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Button
          variant="text-link"
          onClick={this.props.toggleButton}
          className="beratungspopupbutton__text"
        >
          Beratung?
        </Button>
        {this.props.isButtonToggled ? (
          <CloseIcon
            width={this.ICON_SIZE}
            height={this.ICON_SIZE}
            onClick={this.props.toggleButton}
            data-component-id="beratungsweiche-Popup-Button"
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <ConsultantIcon
            title="Wünschen Sie Beratung?"
            width={this.ICON_SIZE}
            height={this.ICON_SIZE}
            onClick={this.props.toggleButton}
            data-component-id="beratungsweiche-Popup-Button"
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
    );
  }
}

export default BeratungspopupButton;
