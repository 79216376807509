import { Config } from './Config';
import { TEST_APP_ID, TEST_ROOT_ID } from './const';

const prodConfig: Config = {
  baseGatewayUrl: '/api',
  appId: document.currentScript?.dataset.appId ?? TEST_APP_ID,
  rootId: document.currentScript?.dataset.rootId ?? TEST_ROOT_ID
};

export default prodConfig;
