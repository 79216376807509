import * as cookies from 'js-cookie';
import { EDCookie } from '../types/EDCookie';

const POSITION_AKTIONSNUMMER = 0;

export const getCookies = (): EDCookie => {
  const cookieValues = cookies.get();

  return {
    aktionNr: getAktionNr(cookieValues.aktionNr),
    fid: cookieValues.fid,
    wmidCookie: cookieValues.wmidCookie
  };
};

const getAktionNr = (aktionNr: string) => {
  // Böse, böse, böse: der AEM-Cookie-Wert enthält ein Komma am Ende bzw nach dem Komma die VermittlerNr.
  // Das zu ändern ist allerdings ein hell-of-a-job weil das mittlerweile von x Tarifen so erwartet wird.
  // Dann splitten wir halt auch... :-(

  if (aktionNr) {
    const split = splitAktionCookie(aktionNr);
    return split[POSITION_AKTIONSNUMMER];
  }

  return undefined;
};

const splitAktionCookie = (aktionNr: string): string[] => {
  aktionNr = aktionNr.replace('%2C', ',');
  return aktionNr.split(',');
};
