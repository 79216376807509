import { Config } from './Config';

// tslint:disable:no-var-requires
const config: Config =
  process.env.NODE_ENV === 'development'
    ? require('./development').default
    : require('./production').default;
// tslint:enable:no-var-requires

export default config;
