export const fillWithZeros = (
  numberToFill: string | number,
  length: number
): string | undefined => {
  if (typeof numberToFill === 'number') {
    numberToFill = String(numberToFill);
  }
  if (numberToFill.length <= length) {
    return ('0'.repeat(length) + numberToFill).slice(-length);
  }
};

export const convertDateToString = (date: Date): string => {
  const day = fillWithZeros(date.getDate(), 2);
  const month = fillWithZeros(date.getMonth() + 1, 2);
  const year = fillWithZeros(date.getFullYear(), 4);
  return [day, month, year].join('.');
};
